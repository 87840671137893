import React from 'react';
import avt01 from '../../../../assets/avt01.png';
import avt02 from '../../../../assets/avt02.png';
import s from './Testimonials.module.css';
import { FaStar } from 'react-icons/fa';

const testimonialsData = [
  {
    name: "Sarah L.",
    avatar: avt01,
    title: "CFO, Dynamic Tech Solutions",
    comment: "Partnering with TETO FINANCE has been a game-changer for our business. Their team quickly identified areas for improvement and provided strategies that helped us reduce costs and increase profitability."
  },
  {
    name: "James M.",
    avatar: avt02,
    title: "Owner, Fresh Grocer Co.",
    comment: "We struggled with cash flow issues for years, but TETO FINANCE provided a detailed plan that got us back on track. Their guidance has been invaluable, and our finances are healthier than ever."
  },
  {
    name: "Amanda K.",
    avatar: avt01,
    title: "CEO, Bright Horizons Marketing",
    comment: "TETO FINANCE has taken the stress out of tax season. They’ve ensured we’re compliant, minimized our tax liabilities, and even helped us find deductions we didn’t know we were eligible for."
  },
  {
    name: "Mark S.",
    avatar: avt02,
    title: "Managing Director, Skyline Ventures",
    comment: "The investment strategies from TETO FINANCE have been spot on. They took the time to understand our goals and helped us build a portfolio that aligns with our long-term objectives."
  }
];

const Testimonials = () => {
  return (
    <div className={s.testimonials} id="testimonials">
      <h2 className={s.main_title}>Testimonials</h2>
      <div className={s.container}>
        {testimonialsData.map((item, index) => (
          <div className={s.box} key={index}>
            <div className={s.image}>
              <img src={item.avatar} alt="avatar" />
            </div>
            <h3>{item.name}</h3>
            <span className={s.title}>{item.title}</span>
            <div className={s.rate}>
              <FaStar className={s.filled} />
              <FaStar className={s.filled} />
              <FaStar className={s.filled} />
              <FaStar className={s.filled} />
              <FaStar className={s.filled} />
            </div>
            <p>{item.comment}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Testimonials;
