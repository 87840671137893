import React from 'react';
import s from './Hero.module.css';
import hero1 from '../../../../assets/hero.jpg';
import { FaQuoteLeft } from 'react-icons/fa';
import classNames from 'classnames';

const Hero = () => {
  return (
    <section className={classNames(s.hero, s.container)} id='home'>
      <div className={s.hero_content}>
        <h1>TETO FINANCE INC LTD</h1>
        <p>
          At TETO Finance Inc Ltd, we are committed to empowering businesses through expert financial management and strategic guidance. Our team of seasoned professionals delivers personalized solutions to help businesses optimize their financial stability and growth. With extensive experience in financial planning, accounting, tax planning, and investment management, we provide trusted support to businesses around the globe. Whether you're looking to streamline your financial processes or develop a robust investment strategy, we are here to help you succeed.
        </p>
        <a href='#services' className={s.get_started_button}>Explore Our Services</a>
      </div>
      <div className={s.hero_image}>
        <img src={hero1} alt='Financial Expertise' />
        <div className={s.hero_image_text}>
          <FaQuoteLeft className={s.icon} />
          <p>
            Empowering businesses with tailored financial solutions for sustainable growth.
          </p>
        </div>
      </div>
    </section>
  );
};

export default Hero;
