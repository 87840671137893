import React from 'react';
import { LuMail } from 'react-icons/lu';
import { SlLocationPin } from 'react-icons/sl';
import { BsTelephone } from "react-icons/bs";
import s from './ContactUs.module.css'; 
import classNames from 'classnames';

const ContactUs = () => {
  return (
    <section className={classNames(s.contact_us, s.container)} id='contact'>
      <h2>Contact Us</h2>
      <p>You can contact us via the email or phone number below, and we'll get back to you as soon as possible.</p>
      <div className={s.block}>
        <BsTelephone className={s.icon} aria-label="Company Number" />
        <p className={s.phone}>+44 1594 4985</p>
      </div>
      <div className={s.block}>
        <LuMail className={s.icon} aria-label="Email" />
        <a className={s.mail} href='mailto:companyincorp02@gmail.com'>
          companyincorp02@gmail.com
        </a>
      </div>
      <div className={s.block}>
        <SlLocationPin className={s.icon} aria-label="Company Address" />
        <p className={s.address}>
          73 Wellington Road North, Stockport, England
        </p>
      </div>
      <div className={s.block}>
        <p className={s.director}>
          Teto Finance Inc Ltd Financial Management Consultancy
        </p>
      </div>
    </section>
  );
};

export default ContactUs;
