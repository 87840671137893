import React, { useState } from 'react';
import s from './Header.module.css';
import classNames from 'classnames';
import { FaBars, FaTimes } from 'react-icons/fa';

const Header = () => {
  const [isVisible, setIsVisible] = useState(false);
  const handleClose = () => {
    setIsVisible(false)
  }

  return (
    <header className={s.header}>
      <div className={classNames(s.container, s.header_wraper)}>
        <a className={s.logo} href='/'>
          Teto Finance Inc Ltd
        </a>
        <nav className={isVisible ? s.nav_links_mobile : s.nav_links}>
          <a href='#home' className={s.nav_link} onClick={handleClose}>Home</a>
          <a href='#services' className={s.nav_link} onClick={handleClose}>Services</a>
          <a href='#about' className={s.nav_link} onClick={handleClose}>About Us</a>
          <a href='#contact' className={s.nav_link} onClick={handleClose}>Contact</a>
        </nav>
        <button
          className={s.mobile_menu_icon}
          onClick={() => setIsVisible(prev => !prev)}>
          {isVisible ? <FaTimes /> : <FaBars />}
        </button>
      </div>
    </header>
  );
};

export default Header;
