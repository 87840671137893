import React from 'react';
import s from './WhyChooseUs.module.css';
import classNames from 'classnames';
import { FaAward, FaCogs, FaHandshake, FaChartBar } from 'react-icons/fa';
import { GiProgression } from 'react-icons/gi';

const WhyChooseUs = () => {
    return (
      <section className={classNames(s.why_choose_us, s.container)} id='about'>
        <h2>Why Choose Us?</h2>
        <p className={s.subtitle}>Choosing TETO FINANCE means partnering with a team that prioritizes your business’s financial success. Here’s why clients trust us:</p>
        <div className={s.why_choose_us_cards}>
          <div className={s.why_choose_us_card}>
            <FaAward className={s.why_choose_us_icon} />
            <h3>Experienced Professionals</h3>
            <p>Our team consists of certified financial experts with extensive industry experience and a commitment to excellence.</p>
          </div>
          <div className={s.why_choose_us_card}>
            <FaCogs className={s.why_choose_us_icon} />
            <h3>Personalized Solutions</h3>
            <p>We understand that each business is unique, so we tailor our services to meet the specific needs of each client.</p>
          </div>
          <div className={s.why_choose_us_card}>
            <FaChartBar className={s.why_choose_us_icon} />
            <h3>Transparent Communication</h3>
            <p>We believe in keeping our clients informed every step of the way, ensuring a clear understanding of their financial health.</p>
          </div>
          <div className={s.why_choose_us_card}>
            <FaHandshake className={s.why_choose_us_icon} />
            <h3>Proactive Approach</h3>
            <p>We don’t just respond to issues—we anticipate them. Our proactive planning helps mitigate risks and optimize growth opportunities.</p>
          </div>
          <div className={s.why_choose_us_card}>
            <GiProgression className={s.why_choose_us_icon} />
            <h3>Proven Results</h3>
            <p>Our clients’ success stories are a testament to the effective, results-driven financial strategies we provide.</p>
          </div>
        </div>
      </section>
    );
};

export default WhyChooseUs;
