import React from 'react';
import s from './Footer.module.css';
import classNames from 'classnames';
import { CiFacebook, CiTwitter, CiLinkedin } from 'react-icons/ci';

const Footer = () => {
  return (
    <footer className={s.footer}>
      <div className={classNames(s.container, s.footer_content)}>
        <div className={s.footer_section}>
          <h3>About Us</h3>
          <p>
            Teto Finance Inc Ltd is a leading financial management consultancy, empowering businesses to achieve financial stability and growth through expert guidance, tailored strategies, and transparent communication.
          </p>
        </div>
        <div className={s.footer_section}>
          <h3>Contact</h3>
          <p>Address: 73 Wellington Road North, Stockport, England</p>
          <p>Email: <a href="mailto:companyincorp02@gmail.com">companyincorp02@gmail.com</a></p>
        </div>
        <div className={s.footer_section}>
          <h3>Follow Us</h3>
          <div className={s.social_icons}>
            <a href='https://facebook.com' target='_blank' rel='noopener noreferrer'>
              <CiFacebook />
            </a>
            <a href='https://twitter.com' target='_blank' rel='noopener noreferrer'>
              <CiTwitter />
            </a>
            <a href='https://linkedin.com' target='_blank' rel='noopener noreferrer'>
              <CiLinkedin />
            </a>
          </div>
        </div>
      </div>
      <div className={s.footer_bottom}>
        <p>&copy; {new Date().getFullYear()} Teto Finance Inc Ltd. All rights reserved.</p>
      </div>
    </footer>
  );
};

export default Footer;
