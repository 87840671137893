import React from 'react';
import s from './Services.module.css';
import { FaDollarSign, FaCalculator, FaFileAlt, FaChalkboardTeacher, FaChartLine, FaCashRegister } from 'react-icons/fa';
import classNames from 'classnames';

const Services = () => {
  return (
    <section className={classNames(s.services, s.container)} id='services'>
      <h2>Our Services</h2>
      <div className={s.service_cards}>
        <div className={s.service_card}>
          <FaDollarSign className={s.icon} />
          <h3>Financial Planning & Analysis</h3>
          <ul>
            <li>Strategic planning to align financial goals with business growth objectives.</li>
            <li>Comprehensive financial analysis to optimize performance and resource allocation.</li>
          </ul>
        </div>
        <div className={s.service_card}>
          <FaCalculator className={s.icon} />
          <h3>Accounting & Bookkeeping</h3>
          <ul>
            <li>Expert accounting services to maintain accurate financial records and ensure compliance.</li>
            <li>Bookkeeping services tailored to the needs of small and medium-sized businesses.</li>
          </ul>
        </div>
        <div className={s.service_card}>
          <FaFileAlt className={s.icon} />
          <h3>Tax Planning & Compliance</h3>
          <ul>
            <li>Effective tax strategies to minimize liabilities and maximize business savings.</li>
            <li>Expert guidance on tax filing, deductions, and compliance with local regulations.</li>
          </ul>
        </div>
        <div className={s.service_card}>
          <FaChalkboardTeacher className={s.icon} />
          <h3>Business Advisory Services</h3>
          <ul>
            <li>Consulting on business structure, strategy, and operations for sustainable growth.</li>
            <li>Support in financial decision-making and long-term planning to drive profitability.</li>
          </ul>
        </div>
        <div className={s.service_card}>
          <FaChartLine className={s.icon} />
          <h3>Investment Management</h3>
          <ul>
            <li>Tailored investment strategies to maximize returns and diversify your portfolio.</li>
            <li>Comprehensive risk management to safeguard investments and enhance growth potential.</li>
          </ul>
        </div>
        <div className={s.service_card}>
          <FaCashRegister className={s.icon} />
          <h3>Cash Flow Management</h3>
          <ul>
            <li>Effective solutions to manage and optimize cash flow for business stability.</li>
            <li>Guidance on budgeting and forecasting to ensure financial flexibility and resilience.</li>
          </ul>
        </div>
      </div>
    </section>
  );
};

export default Services;
